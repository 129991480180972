<template>
    <div class="content">
      <div class="detaile">
        <div class="left">
          <div class="flex-row">
            <div class="title">发送类型：</div>
            <span>{{detail.type==1?'店铺':(detail.type==2?'业态':(detail.type==3?'楼层':''))}}</span>
          </div>
          <!-- <div class="flex-row">
            <div class="title">{{detail.type==1?'店铺':(detail.type==2?'业态':(detail.type==3?'楼层':''))}}</div>
          </div> -->
          <div class="flex-row">
            <div class="conten" >
              <div v-for="(item) in detail.myContent" :key="item">
                <div class="conten-item">{{item}}</div>
              </div>
            </div>
          </div>
          <div class="flex-row" style="flex-wrap: wrap;" v-if="detail.showType==1">
            <div class="title">图片：</div>
            <!-- <div class="images-item" v-for="(item,index) in detail.resource" :key="index">
              <img :src="item.url" alt="" @click="showImg(item)">
            </div> -->
            <viewer :images="(detail.resource||[]).filter(temp=>{
                return ['.png','.jpg','.jpeg','.PNG','.JPG','.JPEG'].includes(temp.url.slice(temp.url.lastIndexOf('.')))
                })" style="display: flex;flex-wrap: wrap;">
              <div class="images-item" v-for="(item,index) in (detail.resource||[]).filter(temp=>{
                return ['.png','.jpg','.jpeg','.PNG','.JPG','.JPEG'].includes(temp.url.slice(temp.url.lastIndexOf('.')))
                })" :key="index">
                <img :src="item.url" :alt="item.name" @click="showImg(item)">
              </div>
            </viewer>
            <div class="images-item" v-for="(item,index) in (detail.resource||[]).filter(temp=>{
                return ['.mp4','.wmv','.m4v','.avi','.ogg','.flv','.rmvb'].includes(temp.url.slice(temp.url.lastIndexOf('.')))
                })" :key="index">
                <!-- <img :src="item.url" alt="视频" @click="showViedo(item)"> -->
                <div  @click="showViedo(item)" style="width: 100%;height: 100%;margin-left: 2px;margin-top: 1px;background: #bbb8b8;display: flex;align-items: center;justify-content: center;">
                  <img style="margin:0;width: 40px;height: 40px;" src="https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/video.png">
                  <!-- <div style="width: 0;height: 0;border: solid 23px #fff;border-radius: 8px;border-top-color: transparent;border-right-color: transparent;border-bottom-color: transparent;transform: translate(61px, 23px);"></div> -->
                </div>
              </div>
            <div  class="wordfile" v-if="viedoUrl" @click="closeViedo">
              <div @click.stop="closeViedo" style="position:absolute;top: 6%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
              <div style="width:80%;height:80%;margin-left: 10%;margin-top: 6%;background: #fff;">
                <video width="100%" height="100%" controls autoplay>
                  <source :src="viedoUrl" type="video/ogg">
                  <source :src="viedoUrl" type="video/mp4">
                  <source :src="viedoUrl" type="video/webm">
                  <object data="movie.mp4" width="100%" height="100%">
                    <embed width="100%" height="100%" :src="viedoUrl">
                  </object>
                </video>
              </div>
            </div>
            <!-- <el-dialog top="5vh" :visible.sync="imgDialogVisible" :title="imgName" width="90%">
              <img width="100%" style="max-height:750px" :src="imageUrl" alt="">
            </el-dialog> -->
          </div>
          <div class="flex-row" style="flex-wrap: wrap;" v-if="detail.showType==1">
            <div class="title">附件：</div>
            <div style="display:flex;flex-wrap: wrap">
            <div class="files-item" v-for="(item,index) in (detail.attachmentResource||[])" :key="index">
            <div class="file-img">
              <img :src="getDefaultImg(item.name)" alt="" @click="getFileImg(item)">
              <div :title="item.name">{{item.name}}</div>
            </div>
          </div>
          <div class="wordfile" v-if="fileUrl" @click="closeIframe">
            <div @click.stop="closeIframe" style="position:absolute;top: 2%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
            <iframe :src="fileUrl" width='80%' height='90%' frameborder='0'/>
          </div>
          </div>
          <!-- <el-dialog top="5vh" :visible.sync="fileDialogVisible" :title="fileName" width="90%">
            <iframe style="width: 100%;height: 100%;" :src="fileUrl" frameborder="0" ></iframe>
          </el-dialog> -->
          </div>
        </div>

        <div class="right">
          <div class="flex-row">
            <div class="title">标题：</div>
            <span style="font-weight: 600;">{{detail.title}}</span>
          </div>
          <div class="flex-row" style="align-items: self-start;flex:1" v-if="detail.showType==1">
            <div class="title">内容：</div>
            <div class="content-content ql-editor" v-html="detail.content"> 
              <!-- 绑定富文本编辑器的值 -->
              
            </div>
          </div>
          <div class="flex-row" v-if="detail.showType==2">
            <div class="title">跳转链接：</div>
            <a :href="detail.linkUrl" target="_blank">
              <span style="word-break: break-all;">{{detail.linkUrl}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button @click="back">返回</el-button>
      </div>
    </div>
</template>

<script>
// import {changVerified, getConfig} from "@/services/sysapi";

export default {
  name: "detail",
  data(){
    return {
      viedoUrl:'',
      noticeType: 1,
      imgDialogVisible: false,
      imageUrl: '',
      fileDialogVisible: false,
      fileName: '',
      fileUrl: '',
      imgName: '',
      detail: {},
    }
  },
  props: {
    id: {
      type: Number,
    },
  },
  created(){
    
  },
  mounted(){
    this.getDetail()
    this.$nextTick(()=>{
      document.addEventListener('keyup',(e)=>{
        if(e.keyCode==27){
          this.viedoUrl&&this.closeViedo()
          this.fileUrl&&this.closeIframe()
        }
      })
    });
  },
  methods:{
    getDetail(){
      let params={
        id: this.id
      }
      this.$api.busapi.getNoticeDetail(params).then(res=>{
        if(res.code==200){
          this.detail =  res.data
          if(this.detail.isAll){
            this.detail.myContent = ['全部']
          }else{
            // this.detail.myContent = []
            let typeName = this.detail.type==1?'shops':(this.detail.type==2?'businesses':(this.detail.type==3?'floors':''))
            this.detail.myContent = this.detail[typeName].map(item=>{
              if(typeName=='shops'){
                return item.name + '(' + item.shopNo + ')'
              }else{
                return item.name
              }
            })
          }
        }else{
          this.detail = {}
          this.$message.warning(res.msg)
        }
      })
    },
    getDefaultImg(fileName){
        const splitIndex = fileName.lastIndexOf('.');
        const str = fileName.slice(splitIndex-1)
        var orign = 'https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/'
        if(str.indexOf('.pdf')!=-1){
          return orign + 'pdf1.png'
        }else if(str.indexOf('.doc')!=-1 || str.indexOf('.docx')!=-1){
          return orign + 'word.png'
        }else if(str.indexOf('.xls')!=-1 || str.indexOf('.xlsx')!=-1){
          return orign + 'excel.png'
        }else if(str.indexOf('.ppt')!=-1 || str.indexOf('.pptx')!=-1){
          return orign + 'file_ppt.png'
        }else if(str.indexOf('.zip')!=-1){
          return orign + 'zip.png'
        }else if(str.indexOf('.rar')!=-1){
          return orign + 'rar.png'
        }else{
          return orign + 'file-cion.png'
        }
        // if(['.zip','.rar','.pdf'].includes(type)){
        //   return require(`@/assets/${fileName.slice(splitIndex+1)}.png`)
        // }else{
        //   return require('@/assets/file_default.png')
        // }
      },
      closeIframe(){
        this.fileUrl = ''
      },
    getFileImg(item){
      const fileType = item.url.substr(item.url.lastIndexOf(".")+1)
      if(['zip','rar'].includes(fileType)){
        this.$message.warning("暂不支持预览zip、rar类型的文件！")
        return
      }
      if(['pdf'].includes(fileType)){
        this.fileUrl = item.url
      }
      if(['doc','docx','xls','xlsx','ppt','pptx'].includes(fileType)){
        this.fileUrl = "https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(item.url)
      }
      this.fileDialogVisible = true
      this.fileName = item.name
    },
    closeViedo(){
        this.viedoUrl = ''
      },
    showViedo(item){
      this.viedoUrl = item.url
    },
    showImg(item){
      this.imageUrl = item.url || item
      this.imgName = item.name || item
      this.imgDialogVisible = true
    },
    back() {
      this.$emit("changeType", 'list');
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  // border-radius: 6px;
  padding: 20px;
  .detaile{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .left{
    flex:1;
    margin-right: 20px;
  }
  .right{
    flex:1;
    display: flex;
    flex-direction: column;
  }
  .flex-row{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 30px
  }
  &::v-deep .el-dialog{
    overflow-y: auto;
  }
  &::v-deep .el-dialog__body{
    height: 750px;
    max-height: 750px;
    overflow-y: auto;
  }
  .conten{
    flex: 1;
    border: solid 1px #D9D9D9;
    min-height: 100px;
    max-height: 200px;
    overflow: auto;
    padding: 10px;
    margin-left: 50px;
    // display: flex;
  }
  .content-content{
    flex: 1;
    border: solid 1px #D9D9D9;
    height: 100%;
    overflow: auto;
    padding: 20px;
    line-height: 20px;
  }
  .conten-item{
    display: inline-block;
    color: #000;
    background: #F2F2F2;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
  .title{
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
    iframe{
      margin-left: 10%;
      margin-top: 3%;
    }
  }
  .images-item{
    width: 150px;
    height: 100px;
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }
  .images-item img{
    width: 100%;
    height: 100%;
  }
  .files-item{
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }
  .file-img{
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .file-img img{
    width: 70px;
    height: 70px;
  }
  .file-img div{
    max-width: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }
  .btn{
    position: fixed;
    bottom: 0;
    background-color: white;
    left: 262px;
    display: flex;
    width: calc(100% - 282px);
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    z-index: 10;
  }
}

</style>