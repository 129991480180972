<template>
  <el-cascader
                v-model="dataObj.value"
                clearable
                filterable
                :options="dataObj.options"
                :props="{
                  multiple: true
                }"
                :collapse-tags="true"
                placeholder="请选择"
                @visible-change="visibleChange"
                @change="selectHandle">
              </el-cascader>
</template>

<script>
import {changVerified, getConfig} from "../../../services/sysapi";

export default {
  name: "index",
  data(){
    return {
      preSelected: [], // 上次选中的数据
      originData: [], //  源数据平铺成一级节点
    }
  },
  props: {
    dataObj:{
      type: Object
    }
  },
  watch:{
    'dataObj':{
      deep: true,
      handler(v){
        this.preSelected = v.value
      }
    }
  },
  created(){
    
  },
  destroyed(){
    // this.preSelected = []
    // this.originData = []
  },
  methods:{
    visibleChange(visible) {
      let _this = this
      if(visible) {
        _this.dataObj.options.unshift({ label: '全选', value: '全选' })
      }else {
        _this.dataObj.options.shift()
      }
    },
    judgetAllSelected(node) {
      // 判断是否是全选,也就是看已选择的选中中包不包含"全选"
      let isAllSelected = false
      for(let i = 0; i < node.length; i++) {
          if(node[i][0] === '全选') {
            isAllSelected = true
            break;
          }
      }
      return isAllSelected
    },
    loopFlatData(list = [], parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopFlatData(e.children, pNode)
        }else {
          if(e.label !== '全选') {
            if(parentNode.length > 0) {
              pNode.push(e.value)
              this.originData.push({ ...e, parentNode: pNode })
            }else {
              this.originData.push(e)
            }
          }
        }
      })
    },
    loopSelectData(list, parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopSelectData(e.children, pNode)
        }else {
          if(parentNode.length > 0) {
            this.dataObj.value.push([...parentNode, e.value])
          }else {
            this.dataObj.value.push([e.value])
          }
        }
      })
    },
    checkIsAddAllSelected() {
      let list = this.dataObj.options; // 原始数据列表
      if(this.originData.length === 0) {
        this.loopFlatData(list) // 把所有的父子级平铺成一个一级列表
      }
      let origin = this.originData;
      let now = [...this.dataObj.value].filter(item => item[0] !== '全选')
      if(origin.length > now.length) {
        // 非全选时, 如果有之前选过全选,要把全选过滤掉
        this.dataObj.value = this.dataObj.value.filter(item => item[0] !== '全选')
      }else {
        // 当所有的数据都选择时, 要自动把全选勾选上
        if(this.dataObj.value[0] && this.dataObj.value[0][0] !== '全选') {
          this.dataObj.value = [['全选'], ...this.dataObj.value]
        }
      }
    },
    async selectHandle(node = []) {
      this.dataObj.value = []
      // 选中的数据格式: [['全选'], ['0'], ['1', '11', '111'], ['2', '21'],...]
      let list = this.dataObj.options
      let current = []; // 获取当前选中的option, 因为element文档中没有获取当前选中的option的方法,所以我通过上一次和本地的选中数据进行对比来获取
      if(node.length >= this.preSelected.length) {
        let keys = this.preSelected.map(item => JSON.stringify(item))
        current = node.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('选中某项', current);
      }else {
        // 取消选中
        let keys = node.map(item => JSON.stringify(item))
        current = this.preSelected.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('取消选中', current);
      }
      // 根据element的选中数据格式, 每一个选项都是一个列表, 列表第一项为父级value, 第二项为选中的子级value, ...以此类推
      const currentValue = current.length > 0 ? current[0][0] || '' : ''
      if(currentValue === '全选') {
        if(this.judgetAllSelected(node)) {
          this.loopSelectData(list)
        }else {
          this.dataObj.value = []
        }
      }else {
        this.dataObj.value = node
      }
      this.checkIsAddAllSelected(); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
      this.preSelected = this.dataObj.value; // 保存上一次的选择结果
      this.changeHandle();
    },
    changeHandle() {
      // 这里是处理成自己需要的数据格式, 需要把全选的这一选项过滤掉
      // 原始选择的数据格式[['全选'], ['1', '11'], ['2', '21'],...]
      console.log('changeHandle: ', this.dataObj.value);
      this.$emit('changeHandle',this.dataObj.value)
    },
  }
}
</script>

<style scoped lang="scss">


</style>